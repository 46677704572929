// src/Components/JobList.js
import React from 'react';
import { Box, Button, Text, VStack, HStack, Link } from '@chakra-ui/react';

const shortenUrl = (url) => {
  try {
    const newUrl = new URL(url);
    return newUrl.hostname; // Get only the domain name (e.g., exlcareers.peoplestrong.com)
  } catch (error) {
    return url; // Return the original URL if parsing fails
  }
};

const JobList = ({ jobs, deleteJob }) => {
  return (
    <VStack spacing={4}>
      {jobs.length === 0 ? (
        <Text>No jobs available. Add some new jobs!</Text>
      ) : (
        jobs.map((job) => (
          <Box 
            key={job.id} 
            p={4} 
            border="1px solid #e2e8f0" 
            borderRadius="md" 
            w="100%"
            bg="gray.50"
          >
            <HStack justify="space-between">
              <Text>
                <strong>{job.company}</strong> : Role : {job.comment} <br />
                (Apply here : 
                <Link href={job.jobUrl} color="teal.500" isExternal>            
                  {shortenUrl(job.jobUrl)}
                </Link>)
              </Text>
              <HStack>
                <Button colorScheme="blue" size="sm" onClick={() => navigator.clipboard.writeText(job.jobUrl)}>
                  Share
                </Button>
                <Button colorScheme="red" size="sm" onClick={() => deleteJob(job.id)}>
                  x
                </Button>
              </HStack>
            </HStack>
          </Box>
        ))
      )}
    </VStack>
  );
};

export default JobList;
