import  { Box, Tag, TagLabel, TagLeftIcon, Wrap, WrapItem } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

function SkillFilter({ skills, selectedSkill, setSelectedSkill }) {
    return (
      <Box mb={4}>
        <Wrap spacing={3} justify="center">
          {skills.map((skill) => (
            <WrapItem key={skill}>
              <Tag
                size="lg"
                borderRadius="full"
                variant={selectedSkill === skill ? 'solid' : 'outline'}
                colorScheme={selectedSkill === skill ? 'teal' : 'gray'}
                color="black"
                cursor="pointer"
                onClick={() => setSelectedSkill(selectedSkill === skill ? null : skill)}
              >
                <TagLeftIcon
                  boxSize="12px"
                  as={selectedSkill === skill ? CheckIcon : undefined}
                />
                <TagLabel>{skill}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
          <WrapItem>
            <Tag
              size="lg"
              borderRadius="full"
              variant={!selectedSkill ? 'solid' : 'outline'}
              colorScheme={!selectedSkill ? 'teal' : 'gray'}
              color="black"
              cursor="pointer"
              onClick={() => setSelectedSkill(null)}
            >
              <TagLabel>All</TagLabel>
            </Tag>
          </WrapItem>
        </Wrap>
      </Box>
    );
  }


  export default SkillFilter;