import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, Flex } from '@chakra-ui/react';
import JobList from './Components/JobList';
import JobForm from './Components/JobForm';
import Testimonials from './Components/Testimonial';
import Footer from './Components/Footer';
import UpdateBox from './Components/UpdateBox';
import ToastNotification from './Components/ToastNotification';
import HeroSection from './Components/HeroSection';
import UsefulLinks from './Components/UsefulLinks';
import SkillFilter from './Components/SkillFilter';


axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function App() {
  const [jobs, setJobs] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [skills, setSkills] = useState([]);

  const fetchJobs = useCallback(async () => {
    try {
      const response = await axios.get('/jobs');
      const normalizedJobs = response.data.map((job) => ({
        ...job,
        skill: Array.isArray(job.skill) ? job.skill : job.skill ? [job.skill] : [],
      }));
      setJobs(normalizedJobs);
      extractSkills(normalizedJobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  }, []);

  const extractSkills = (jobs) => {
    const allSkills = jobs.flatMap((job) => job.skill || []);
    const uniqueSkills = [...new Set(allSkills)];
    setSkills(uniqueSkills);
  };

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const addJob = async (job) => {
    try {
      const payload = {
        jobUrl: job.jobUrl,
        company: job.company,
        comment: job.comment,
        skill: job.skill,
      };
      await axios.post('/add', payload);
      setShowToast(true);
      fetchJobs();
    } catch (error) {
      console.error('Error adding job:', error);
    }
  };

  const deleteJob = async (id) => {
    try {
      await axios.delete(`/delete/${id}`, {
        data: { id: parseInt(id, 10) },
      });
      fetchJobs();
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const filteredJobs = selectedSkill
    ? jobs.filter((job) => job.skill && job.skill.includes(selectedSkill))
    : jobs;

  return (
    <Box p={[4, 5]}>
      <HeroSection />

      {/* Main Layout with SkillFilter and JobForm on the left, JobList on the right */}
      <Flex direction={['column', 'row']} justify="space-between" align="start" mb={10}>
        {/* Left side: Skill Filter and Job Form */}
        <Box flex="1" mr={[0, 6]} mb={[6, 0]}>
          <SkillFilter
            skills={skills}
            selectedSkill={selectedSkill}
            setSelectedSkill={setSelectedSkill}
          />
          <JobForm addJob={addJob} />
        </Box>

        {/* Right side: Job List */}
        <Box flex="2" maxH="600px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="md" p={4}>
          <JobList jobs={filteredJobs} deleteJob={deleteJob} />
        </Box>
      </Flex>

      {/* Additional Sections */}
      <Testimonials />
      <UsefulLinks />
      <UpdateBox />
      <Footer />

      <ToastNotification isOpen={showToast} onClose={() => setShowToast(false)} />
    </Box>
  );
}

export default App;
