import React, { useState } from 'react';
import { Box, Input, Button, FormControl, FormLabel, Textarea } from '@chakra-ui/react';

function JobForm({ addJob }) {
  const [jobUrl, setJobUrl] = useState('');
  const [company, setCompany] = useState('');
  const [skill, setSkill] = useState('fullstack');
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addJob({ jobUrl, company, comment ,skill});
    setJobUrl('');
    setCompany('');
    setSkill('');
    setComment('');
  };

  return (
    <Box mb={5} paddingLeft={"1.5rem"}>
      <form onSubmit={handleSubmit}>
        <FormControl id="job-url" mb={3} isRequired>
          <FormLabel>Job URL</FormLabel>
          <Input
            type="url"
            placeholder="Enter job URL"
            value={jobUrl}
            borderColor={"blackAlpha.500"}
            onChange={(e) => setJobUrl(e.target.value)}
          />
        </FormControl>
        <FormControl id="company" mb={3} isRequired>
          <FormLabel>Company</FormLabel>
          <Input
            type="text"
           borderColor={"blackAlpha.500"}
            placeholder="Enter company name"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </FormControl>
        <FormControl id="skill" mb={3} isRequired>
          <FormLabel>Skill</FormLabel>
          <Input
            type="text"
            borderColor={"blackAlpha.500"}
            placeholder="Enter primary skill"
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
          />
        </FormControl>
        <FormControl id="comment" mb={3}>
          <FormLabel>Comment</FormLabel>
          <Textarea
            placeholder="Enter your comment (optional)"
            value={comment}
            borderColor={"blackAlpha.500"}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" size="md">
          Add Job
        </Button>
      </form>
    </Box>
  );
}

export default JobForm;
