import React from 'react';
import { Box, Flex, Text, Button, Stack } from '@chakra-ui/react';

const Footer = () => {
return (
    <Box bg="teal.500" color="white" py={8} px={5}>
    <Flex 
      direction={['column', 'row']} 
      justify={['center', 'space-between']} 
      align="center" 
      maxW="1200px" 
      mx="auto" 
      textAlign={['center', 'left']}
    >
   <Text fontSize="lg" mb={[4, 0]}>
          © 2024 JobFinder. All rights reserved.
        </Text>
        
        <Text fontSize="sm" maxW="400px" mb={[4, 0]} textAlign={['center', 'left']}>
          This small web app was created during my job search when I found it challenging to copy and paste each URL to send to multiple people. That's how the idea originated.
        </Text>
        <Text fontSize="sm" maxW="400px" mb={[4, 0]} textAlign={['center', 'left']} > ~Varuog </Text>
            <Stack direction={['column', 'row']} mt={[4, 0]} spacing={4}>
                <Button as="a" href="https://github.com/varuogm" variant="link" color="white">Contact Us</Button>
                <Button as="a" href="https://www.varuog.xyz/" variant="link" color="white">Blogs</Button>

            </Stack>
        </Flex>
    </Box>
);
};

export default Footer;
