import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

const ToastNotification = ({ isOpen, onClose }) => {
  const toast = useToast();

  useEffect(() => {
    if (isOpen) {
      toast({
        title: "Successfully Added a Job",
        description: "The job has been added.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      onClose();
    }
  }, [isOpen, toast, onClose]);

  return null;
};

export default ToastNotification;
