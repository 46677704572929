import React from 'react';
import { Box, Heading, Text} from '@chakra-ui/react';

const UpdateBox = () => {
  return (
    <Box 
      bg="gray.100" 
      borderRadius="md" 
      p={4} 
      boxShadow="md" 
    //   maxW="400px" 
      mx="auto" 
      mb={6}
    >
      <Heading as="h3" size="lg" mb={2}>
        Upcoming Updates
      </Heading>
      <Text fontSize="md" color="gray.700">
        We are excited to announce new features coming soon, including:
      </Text>
      <Text fontSize="md" color="gray.700" mt={2}>
        - Advanced job search filters (filtering based on domain and skills)
      </Text>
      <Text fontSize="md" color="gray.700">
        - User profile customization and SSO Authentication
      </Text>
      <Text fontSize="md" color="gray.700">
        - Notifications for job postings matching your preferences)
      </Text>
    <Heading as="h4" size="sm" mt={1}></Heading>
    </Box>
  );
};

export default UpdateBox;
