import React from 'react';
import { Box, Heading, Grid, VStack, Text } from '@chakra-ui/react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Testimonial = ({ name, position, image, quote }) => {
  return (
    <VStack textAlign="center" spacing={4} p={5} bg="white" borderRadius="md" shadow="md">
      <LazyLoadImage
        src={image}
        alt={name}
        effect="blur"
        style={{ borderRadius: '50%', width: '100px', height: '100px', objectFit: 'cover' }}
      />
      <Text fontWeight="bold">{name}</Text>
      <Text fontSize="sm" color="gray.500">{position}</Text>
      <Text fontStyle="italic">"{quote}"</Text>
    </VStack>
  );
};

const Testimonials = () => {
  return (
    <Box bg="gray.100" p={8} borderRadius="md" mb={10}>
      <Heading as="h2" size="xl" textAlign="center" mb={5}>
        What People Are Saying
      </Heading>
      <Grid 
        templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']} // One column on mobile, three on desktop
        gap={8}
      >
        <Testimonial
          name="Meow Ji"
          position="Software Engineer"
          image="https://media-be.chewy.com/wp-content/uploads/2023/09/07121821/happy-cat.jpg"
          quote="Absolutely meow meow meow product"
        />
        <Testimonial
          name="Miss Jennie"
          position="intern"
          image="https://media1.tenor.com/m/23tMvM3V-V0AAAAC/cat-smirk.gif"
          quote="I was able to find several great job opportunities. Highly recommend!"
        />
        <Testimonial
          name="Billu, the Cat"
          position="UI/UX Designer"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYCi_zX7wraf4lbXxxa-VCb4YuxcYUEsze4iTsFmm18aEK7I1FBrQXyTwkvpY2yauAdL0&usqp=CAU"
          quote="The platform made job searching fun and efficient!"
        />
      </Grid>
    </Box>
  );
};

export default Testimonials;
