import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HeroSection = () => {
  return (
    <Flex 
      direction={['column', 'row']} 
      align="center" 
      justify="center" 
      mb={10} 
      textAlign={['center', 'left']}
    >
      <LazyLoadImage 
        src="https://static.vecteezy.com/system/resources/thumbnails/044/876/360/small_2x/cat-with-glasses-meme-sticker-tshirt-illustration-free-png.png"
        alt="Find Your Dream Job"
        borderRadius="md"
        maxW={['50%', '50%']}
      />
      <Box mt={[5, 0]} ml={[0, 10]}>
        <Heading as="h1" size="2xl" color="teal.500">
          Find Your Dream Job
        </Heading>
        <Text fontSize="lg" color="gray.600" mt={2}>
          Search, Share, Apply, Succeed! 🚀
        </Text>
      </Box>
    </Flex>
  );
};

export default HeroSection;
