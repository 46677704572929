import React from "react";
import { Box, Heading, List, ListItem, Link } from "@chakra-ui/react";

// Static links data
const pinnedLinks = [
  { id: 1, title: "Notion Workspace", url: "https://notion.so" },
  { id: 2, title: "Targetted companies", url: "https://github.com/Kaustubh-Natuskar/moreThanFAANGM" },
  { id: 3, title: "Stack Overflow", url: "https://stackoverflow.com" },
];

const UsefulLinks = () => {
  return (
    <Box
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      p={4}
      bg="gray.50"
      shadow="md"
      maxW="400px"
      mx="auto"
      mt={8}
    >
      <Heading as="h2" size="md" mb={4} textAlign="center">
        📌 Useful Links
      </Heading>
      <List spacing={3}>
        {pinnedLinks.map((link) => (
          <ListItem key={link.id}>
            <Link 
              href={link.url} 
              isExternal 
              color="blue.500" 
              _hover={{ textDecoration: "underline", color: "blue.600" }}
            >
              {link.title}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default UsefulLinks;
